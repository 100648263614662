import React from 'react'
import NumberFormat from 'react-number-format'

/**
 * NumberFormat para el manejo de divisas
 * @param props parent props
 * @returns {JSX.Element} NumberFormat
 */
export const NumberFormatCustom = (props: {
  [x: string]: any
  name?: string
  inputRef?: any
  onChange?: any
}): JSX.Element => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalScale={2}
      thousandSeparator
      prefix="$"
    />
  )
}
