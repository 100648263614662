import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api, { getAuthorizationHeader } from '../api'

export const getCurrentMargin = createAsyncThunk('margins/last', async (_, thunkAPI) => {
  return await api
    .get('margins/last', {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        localStorage.setItem('isAuthenticated', 'false')
      }
      return thunkAPI.rejectWithValue(e.response.data)
    })
})

export const postMargin = createAsyncThunk('post/margins', async ({ data }: any, thunkAPI) => {
  return await api
    .post('margins', data, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      return thunkAPI.rejectWithValue(e.response.data)
    })
})

const initialState = {
  minimum_margin: 0,
  maximum_discount: 0,
  isFetchingMargin: false,
  isSuccessMargin: false,
  isErrorMargin: false,
  isFetchingPost: false,
  isSuccessPost: false,
  isErrorPost: false,
  errorMessage: null,
}

export const marginSlice = createSlice({
  name: 'margin',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isFetchingMargin = false
      state.isSuccessMargin = false
      state.isErrorMargin = false
      state.errorMessage = null
      return state
    },
    clearStatePost: (state) => {
      state.isFetchingPost = false
      state.isSuccessPost = false
      state.isErrorPost = false
      state.errorMessage = null
      return state
    },
    updateValues: (state, { payload }) => {
      state.minimum_margin = payload.margin
      state.maximum_discount = payload.discount
      return state
    },
  },
  extraReducers: {
    [getCurrentMargin.fulfilled as any]: (state, { payload }) => {
      state.isFetchingMargin = false
      state.isSuccessMargin = true
      state.minimum_margin = payload.minimum_margin
      state.maximum_discount = payload.maximum_discount
    },
    [getCurrentMargin.pending as any]: (state) => {
      state.isFetchingMargin = true
    },
    [getCurrentMargin.rejected as any]: (state, { payload }) => {
      state.isFetchingMargin = false
      state.isErrorMargin = true
      state.minimum_margin = initialState.minimum_margin
      state.maximum_discount = initialState.maximum_discount
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [(postMargin as any).fulfilled]: (state, { payload }) => {
      console.log('payload', payload)
      state.isFetchingPost = false
      state.isSuccessPost = true
      state.maximum_discount = payload.maximum_discount
      state.minimum_margin = payload.minimum_margin
    },
    [(postMargin as any).pending]: (state) => {
      state.isFetchingPost = true
    },
    [(postMargin as any).rejected]: (state, { payload }) => {
      state.isFetchingPost = false
      state.isErrorPost = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
  },
})

export const { clearState, clearStatePost, updateValues } = marginSlice.actions

export const selectMargin = (state: { margin: any }) => state.margin

export default marginSlice.reducer
