import React from 'react'
import { ROLE_ID_USER, ROLE_ID_ADMIN } from './helpers/interfaces/Users'

/**
 * ? Rutas del cotizador de llantas
 */

//const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const TableData = React.lazy(() => import('./views/tabledata/TableData'))
const Quotation = React.lazy(() => import('./views/quotation/Quotation'))
const MyQuotations = React.lazy(() => import('./views/quotation/MyQuotations'))
const Users = React.lazy(() => import('./views/users/Users'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

export const routes = [
  { path: '/', exact: true, name: 'Home', minimumPermissions: ROLE_ID_USER },
  /**
   * !Deprecated since: 1.1.5
   */
  // {
  //   path: 'dashboard',
  //   exact: true,
  //   name: 'Dashboard',
  //   element: Dashboard,
  //   minimumPermissions: ROLE_ID_USER,
  // },
  {
    path: 'quotation',
    exact: true,
    name: 'Quotation',
    element: Quotation,
    minimumPermissions: ROLE_ID_USER,
  },
  {
    path: 'myquotations',
    exact: true,
    name: 'My Quotations',
    element: MyQuotations,
    minimumPermissions: ROLE_ID_USER,
  },
  {
    path: 'tabledata',
    exact: true,
    name: 'Table Data',
    element: TableData,
    minimumPermissions: ROLE_ID_USER,
  },
  { path: 'users', exact: true, name: 'Users', element: Users, minimumPermissions: ROLE_ID_ADMIN },
  { path: '*', exact: true, name: 'Not Found', element: Page404, minimumPermissions: ROLE_ID_USER },
  {
    path: '/500',
    exact: true,
    name: 'Error con la conexión a internet',
    element: Page500,
    minimumPermissions: ROLE_ID_USER,
  },
]
