import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api, { getAuthorizationHeader } from '../api'
import axios from 'axios'

export const loginUser = createAsyncThunk('login', async ({ data }: any, thunkAPI) => {
  return await axios
    .post(process.env.REACT_APP_API_URL + 'login', data)
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      return thunkAPI.rejectWithValue(e.response.data)
    })
})

export const getAllUsers = createAsyncThunk('users', async (_, thunkAPI) => {
  return await api
    .get('users', {
      params: {
        active: 1,
      },
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        localStorage.setItem('isAuthenticated', 'false')
      }
      return thunkAPI.rejectWithValue(e.response.data)
    })
})

export const signIn = createAsyncThunk('signin', async ({ data }: any, thunkAPI) => {
  return await api
    .post('signin', data, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        localStorage.setItem('isAuthenticated', 'false')
      }
      return thunkAPI.rejectWithValue(e.response.data)
    })
})

export const updateUser = createAsyncThunk('users/update', async ({ data }: any, thunkAPI) => {
  return await api
    .put('users/update', data, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        localStorage.setItem('isAuthenticated', 'false')
      }
      return thunkAPI.rejectWithValue(e.response.data)
    })
})

export const desactivateUser = createAsyncThunk(
  'users/desactivate',
  async ({ data }: any, thunkAPI) => {
    return await api
      .put('users/desactivate', data, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      })
      .then((response) => {
        return response.data.data
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem('isAuthenticated', 'false')
        }
        return thunkAPI.rejectWithValue(e.response.data)
      })
  },
)

export const getSubsidiaries = createAsyncThunk(
  'subsidiaries',
  async (params: null | any, thunkAPI) => {
    return await api
      .get('subsidiaries', {
        ...params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      })
      .then((response) => {
        return response.data
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem('isAuthenticated', 'false')
        }
        return thunkAPI.rejectWithValue(e.response.data)
      })
  },
)

export const getAllCompanies = createAsyncThunk('companies', async (_, thunkAPI) => {
  return await api
    .get('companies', {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        localStorage.setItem('isAuthenticated', 'false')
      }
      return thunkAPI.rejectWithValue(e.response.data)
    })
})

const initialState = {
  email: null,
  name: null,
  subsidiary_id: null,
  company_id: null,
  sidebarShow: true,
  unfoldable: true,
  offcanvaShow: false,
  cart: [],
  role_id: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  users: null,
  subsidiaries: [
    {
      id: 0,
      name: 'n/a',
    },
  ],
  companies: [
    {
      id: 0,
      code: 'n/a',
      name: 'n/a',
    },
  ],
  isFetchingUsers: false,
  isSuccessUsers: false,
  isErrorUsers: false,
  isFetchingUpdateUser: false,
  isSuccessUpdateUser: false,
  isErrorUpdateUser: false,
  isFetchingCreateUser: false,
  isSuccessCreateUser: false,
  isErrorCreateUser: false,
  isFetchingSubsidiaries: false,
  isSuccessSubsidiaries: false,
  isErrorSubsidiaries: false,
  isFetchingCompanies: false,
  isErrorCompanies: false,
  isSuccessCompanies: false,
  isFetchingDesactivate: false,
  isSuccessDesactivate: false,
  isErrorDesactivate: false,
  errorMessage: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isFetching = false
      state.errorMessage = null
      return state
    },
    clearStateUpdate: (state) => {
      state.isSuccessUpdateUser = false
      state.isFetchingUpdateUser = false
      state.isErrorUpdateUser = false
      state.errorMessage = null
      return state
    },
    clearStateCreate: (state) => {
      state.isSuccessCreateUser = false
      state.isFetchingCreateUser = false
      state.isErrorCreateUser = false
      state.errorMessage = null
      return state
    },
    clearStateDesactivate: (state) => {
      state.isFetchingDesactivate = false
      state.isSuccessDesactivate = false
      state.isErrorDesactivate = false
      state.errorMessage = null
      return state
    },
    reset: () => initialState,
    setSidebarShow: (state, { payload }) => {
      state.sidebarShow = payload
      return state
    },
    sideBarUnfoldable: (state, { payload }) => {
      state.unfoldable = payload
      return state
    },
    setOffCanvaShow: (state, { payload }) => {
      state.offcanvaShow = payload
      return state
    },
    clearCart: (state) => {
      state.cart = []
      return state
    },
    addRowCart: (state, { payload }) => {
      let newArray = state.cart
      newArray.push(payload.new_row as never)
      state.cart = newArray
      return state
    },
    updateCart: (state, { payload }) => {
      state.cart = payload.cart
      return state
    },
  },
  extraReducers: {
    [(loginUser as any).fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.isSuccess = true
      state.email = payload.user.email
      state.name = payload.user.name
      state.subsidiary_id = payload.user.subsidiary_id
      state.company_id = payload.user.company_id
      state.role_id = payload.user.role_id
      localStorage.setItem('token', payload.token)
      localStorage.setItem('isAuthenticated', 'true')
    },
    [(loginUser as any).pending]: (state) => {
      state.isFetching = true
    },
    [(loginUser as any).rejected]: (state, { payload }) => {
      state.isFetching = false
      state.isError = true
      ;(state as any).errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [(getAllUsers as any).fulfilled]: (state, { payload }) => {
      state.isFetchingUsers = false
      state.isSuccessUsers = true
      state.users = payload.data
    },
    [(getAllUsers as any).pending]: (state) => {
      state.isFetchingUsers = false
    },
    [(getAllUsers as any).rejected]: (state, { payload }) => {
      state.isFetchingUsers = false
      state.isErrorUsers = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [(updateUser as any).fulfilled]: (state) => {
      state.isFetchingUpdateUser = false
      state.isSuccessUpdateUser = true
    },
    [(updateUser as any).pending]: (state) => {
      state.isFetchingUpdateUser = false
    },
    [(updateUser as any).rejected]: (state, { payload }) => {
      state.isFetchingUpdateUser = false
      state.isErrorUpdateUser = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload?.message + '. ' + JSON.stringify(payload?.data)
          : payload?.message
        : 'Error en el servidor'
    },
    [(desactivateUser as any).fulfilled]: (state) => {
      state.isFetchingDesactivate = false
      state.isSuccessDesactivate = true
    },
    [(desactivateUser as any).pending]: (state) => {
      state.isFetchingDesactivate = false
    },
    [(desactivateUser as any).rejected]: (state, { payload }) => {
      state.isFetchingDesactivate = false
      state.isErrorDesactivate = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload?.message + '. ' + JSON.stringify(payload?.data)
          : payload?.message
        : 'Error en el servidor'
    },
    [(signIn as any).fulfilled]: (state) => {
      state.isFetchingCreateUser = false
      state.isSuccessCreateUser = true
    },
    [(signIn as any).pending]: (state) => {
      state.isFetchingCreateUser = false
    },
    [(signIn as any).rejected]: (state, { payload }) => {
      state.isFetchingCreateUser = false
      state.isErrorUpdateUser = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload?.message + '. ' + JSON.stringify(payload?.data)
          : payload?.message
        : 'Error en el servidor'
    },
    [(getSubsidiaries as any).fulfilled]: (state, { payload }) => {
      state.isFetchingSubsidiaries = false
      state.isSuccessSubsidiaries = true
      state.subsidiaries = payload.data
    },
    [(getSubsidiaries as any).pending]: (state) => {
      state.isFetchingSubsidiaries = false
    },
    [(getSubsidiaries as any).rejected]: (state, { payload }) => {
      state.isFetchingSubsidiaries = false
      state.isErrorSubsidiaries = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload?.message + '. ' + JSON.stringify(payload?.data)
          : payload?.message
        : 'Error en el servidor'
    },
    [(getAllCompanies as any).fulfilled]: (state, { payload }) => {
      state.isFetchingCompanies = false
      state.isSuccessCompanies = true
      state.companies = payload.data
    },
    [(getAllCompanies as any).pending]: (state) => {
      state.isFetchingCompanies = false
    },
    [(getAllCompanies as any).rejected]: (state, { payload }) => {
      state.isFetchingCompanies = false
      state.isErrorCompanies = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
  },
})

export const {
  clearState,
  clearStateUpdate,
  clearStateCreate,
  clearStateDesactivate,
  reset,
  setSidebarShow,
  sideBarUnfoldable,
  setOffCanvaShow,
  clearCart,
  addRowCart,
  updateCart,
} = userSlice.actions

export const selectUser = (state: { user: any }) => state.user

export default userSlice.reducer
