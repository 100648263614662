import React from 'react'
import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'

function NumberFormatCustom(props: { inputRef: any; name: string; onChange: any }) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: 'monto',
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  )
}

function FormInputNumber({ name, control, label }: { name: string; control: any; label: string }) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue="0"
      rules={{
        required: 'Campo obligatorio',
        min: {
          value: 0,
          message: 'No se pueden ingresar montos menores a 0',
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <TextField
            helperText={error ? error.message : null}
            size="small"
            error={!!error}
            onChange={onChange}
            value={value}
            fullWidth
            label={label}
            variant="outlined"
            InputProps={
              {
                inputComponent: NumberFormatCustom,
              } as any
            }
          />
        )
      }}
    />
  )
}

export default FormInputNumber
