export const ROLE_ID_USER = 4
export const ROLE_ID_GESTOR = 3
export const ROLE_ID_ADMIN = 2
export const ROLE_ID_SA = 1

export interface UserUpdateParams {
  email: string
  name?: string
  password?: string
  subsidiary_id?: number
  role_id?: number
  company_id?: number
  phone?: string | null | undefined
  backoffices?: any
}

export interface UsersListElement {
  id: number
  name: string
  email: string
  created_at: string | null
  updated_at: string | null
  username: string | null
  phone: string | null
  active: boolean | null
  subsidiary_id: number
  role_id: number
  company_id: number
  role: {
    id: number
    name: 'sa' | 'admin' | 'gestor' | 'user'
    description: string
    created_at: string
  } | null
  backoffices: [
    {
      id: number
      email: string
    },
  ]

  subsidiary: {
    id: number
    name: string
    location: string | null
    address: string | null
    created_at: string
  } | null

  company: {
    id: number
    code: string
    name: string
    created_at: string
  } | null
}
