import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
  return (
    <CFooter>
      <div>
        <span className="ms-1">
          &copy; 2022 Lubtrac. version{' '}
          {process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : 'Dev'}
        </span>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
