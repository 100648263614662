import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { CBadge } from '@coreui/react'

export const AppSidebarNav = ({ items }: any) => {
  const location = useLocation()
  const navLink = (
    name: any,
    icon: any,
    badge:
      | {
          color: string | undefined
          text:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined
        }
      | undefined,
  ) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (
    item: { [x: string]: any; component: any; name: any; badge: any; icon: any },
    index: React.Key | null | undefined,
  ) => {
    const { component, name, badge, icon, ...rest } = item
    const Component = component
    return (
      <Component
        {...(rest.to &&
          !rest.items && {
            component: NavLink,
          })}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    )
  }
  const navGroup = (
    item: { [x: string]: any; items?: any; component?: any; name?: any; icon?: any; to?: any },
    index: React.Key | null | undefined,
  ) => {
    const { component, name, icon, to, ...rest } = item
    const Component = component
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon, undefined)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map(
          (
            item: {
              [x: string]: any
              items?: any
              component?: any
              name?: any
              icon?: any
              to?: any
              badge?: any
            },
            index: React.Key | null | undefined,
          ) => (item.items ? navGroup(item, index) : navItem(item as any, index)),
        )}
      </Component>
    )
  }

  return (
    <React.Fragment>
      {items &&
        items.map(
          (
            item: {
              [x: string]: any
              items?: any
              component?: any
              name?: any
              icon?: any
              to?: any
              badge?: any
            },
            index: React.Key | null | undefined,
          ) => (item.items ? navGroup(item, index) : navItem(item as any, index)),
        )}
    </React.Fragment>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}
