import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilNotes, cilSearch, cilUser, cilDollar } from '@coreui/icons'
import { CNavItem, CNavTitle } from '@coreui/react'
import { ROLE_ID_ADMIN, ROLE_ID_USER } from './helpers/interfaces/Users'

const _nav = [
  {
    component: CNavTitle,
    name: 'Cotizador de llantas',
    minimumPermissions: ROLE_ID_USER,
  },
  {
    component: CNavItem,
    name: 'Cotizador',
    to: '/quotation',
    icon: <CIcon icon={cilSearch} customClassName="nav-icon" />,
    minimumPermissions: ROLE_ID_USER,
  },
  {
    component: CNavItem,
    name: 'Mis Cotizaciones',
    to: '/myquotations',
    icon: <CIcon icon={cilDollar} customClassName="nav-icon" />,
    minimumPermissions: ROLE_ID_USER,
  },
  {
    component: CNavItem,
    name: 'Tabla de datos',
    to: '/tabledata',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    minimumPermissions: ROLE_ID_USER,
  },
  {
    component: CNavItem,
    name: 'Usuarios',
    to: '/users',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    minimumPermissions: ROLE_ID_ADMIN,
  },
]

export default _nav
