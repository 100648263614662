import React from 'react'
import { CToast, CToastBody, CToastHeader } from '@coreui/react'

/**
 * Genera un elemento Toast para mostrar una notificación en la página
 * @param {string}  title - Titulo del anuncio
 * @param {string}  message - Descripcion del anuncio
 * @param {'success' | 'warning' | 'error'}  type - Tipo de anuncio
 * @returns {JSX.Element} Toaster call
 */
export const GeneralToast = (props: {
  title: string
  message: string
  type: 'success' | 'warning' | 'error'
}): JSX.Element => {
  const { title, message, type } = props

  let colorToaster = '#007aff'
  switch (type) {
    case 'success':
      colorToaster = '#198754'
      break
    case 'warning':
      colorToaster = '#ffc107'
      break
    case 'error':
      colorToaster = '#dc3545'
      break
  }

  return (
    <CToast>
      <CToastHeader closeButton>
        <svg
          className="rounded me-2"
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
          role="img"
        >
          <rect width="100%" height="100%" fill={colorToaster}></rect>
        </svg>

        <strong className="me-auto">{title}</strong>

        <small></small>
      </CToastHeader>

      <CToastBody>{message}</CToastBody>
    </CToast>
  )
}
