import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api, { getAuthorizationHeader } from '../api'

export const getCurrencyBanxico = createAsyncThunk('currency/banxico', async (_, thunkAPI) => {
  return await api
    .get('currency/banxico', {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        localStorage.setItem('isAuthenticated', 'false')
      }
      return thunkAPI.rejectWithValue(e.response.data)
    })
})

export const getProductLastFileUpdate = createAsyncThunk(
  'product/lastupdate',
  async (_, thunkAPI) => {
    return await api
      .get('product/lastupdate', {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      })
      .then((response) => {
        return response.data.data
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem('isAuthenticated', 'false')
        }
        return thunkAPI.rejectWithValue(e.response.data)
      })
  },
)

export const getCurrentCurrency = createAsyncThunk('get/currency', async (_, thunkAPI) => {
  return await api
    .get('currency', {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        localStorage.setItem('isAuthenticated', 'false')
      }
      return thunkAPI.rejectWithValue(e.response.data)
    })
})

export const updateCurrency = createAsyncThunk('post/currency', async ({ data }: any, thunkAPI) => {
  return await api
    .post('currency', data, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((e) => {
      return thunkAPI.rejectWithValue(e.response.data)
    })
})

const initialState = {
  currencyGlobal: 0,
  currencyCoin: 'MXN',
  bmxdolar: 0,
  currencyLastUpdate: 0,
  isFetchingBanxico: false,
  isSuccessBanxico: false,
  isErrorBanxico: false,
  isFetchingLastUpdate: false,
  isSuccessLastUpdate: false,
  isErrorLastUpdate: false,
  isFetchingCurrency: false,
  isSuccessCurrency: false,
  isErrorCurrency: false,
  isFetchingUpdateCurrency: false,
  isSuccessUpdateCurrency: false,
  isErrorUpdateCurrency: false,
  errorMessage: null,
}

export const currencySlice = createSlice({
  name: 'currency',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isErrorBanxico = false
      state.isSuccessBanxico = false
      state.isFetchingBanxico = false
      state.isErrorLastUpdate = false
      state.isSuccessLastUpdate = false
      state.isFetchingLastUpdate = false
      state.isErrorCurrency = false
      state.isSuccessCurrency = false
      state.isErrorCurrency = false
      state.isFetchingUpdateCurrency = false
      state.isSuccessUpdateCurrency = false
      state.isErrorUpdateCurrency = false
      state.errorMessage = null
      return state
    },
    changeCoinCurrency: (state, { payload }) => {
      state.currencyCoin = payload.value
      return state
    },
    changeCurrencyGlobal: (state, { payload }) => {
      state.currencyGlobal = payload.value
      return state
    },
    reset: () => initialState,
  },
  extraReducers: {
    [getCurrencyBanxico.fulfilled as any]: (
      state: { isFetchingBanxico: boolean; isSuccessBanxico: boolean; bmxdolar: any },
      { payload }: any,
    ) => {
      state.isFetchingBanxico = false
      state.isSuccessBanxico = true
      state.bmxdolar = payload
    },
    [getCurrencyBanxico.pending as any]: (state) => {
      state.isFetchingBanxico = true
    },
    [getCurrencyBanxico.rejected as any]: (state, { payload }) => {
      state.isFetchingBanxico = false
      state.isErrorBanxico = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [getProductLastFileUpdate.fulfilled as any]: (state, { payload }) => {
      state.isFetchingLastUpdate = false
      state.isSuccessLastUpdate = true
      if (payload.last_day_date) {
        state.currencyLastUpdate = payload.last_day_date.last_update
      }
    },
    [getProductLastFileUpdate.pending as any]: (state) => {
      state.isFetchingLastUpdate = true
    },
    [getProductLastFileUpdate.rejected as any]: (state, { payload }) => {
      state.isFetchingLastUpdate = false
      state.isErrorLastUpdate = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [getCurrentCurrency.fulfilled as any]: (state, { payload }) => {
      state.isFetchingCurrency = false
      state.isSuccessCurrency = true
      let new_date = new Date(payload.updated_at)
      state.currencyGlobal = payload.usd_value
      state.currencyLastUpdate = new_date.toLocaleDateString('es-MX') as any
    },
    [getCurrentCurrency.pending as any]: (state) => {
      state.isFetchingCurrency = true
    },
    [getCurrentCurrency.rejected as any]: (state, { payload }) => {
      state.isFetchingCurrency = false
      state.isErrorCurrency = true
      state.currencyGlobal = initialState.currencyGlobal
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [(updateCurrency as any).fulfilled]: (state) => {
      state.isFetchingUpdateCurrency = false
      state.isSuccessUpdateCurrency = true
    },
    [(updateCurrency as any).pending]: (state) => {
      state.isFetchingUpdateCurrency = true
    },
    [(updateCurrency as any).rejected]: (state, { payload }) => {
      state.isFetchingUpdateCurrency = false
      state.isErrorUpdateCurrency = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
  },
})

export const { clearState, reset, changeCoinCurrency, changeCurrencyGlobal } = currencySlice.actions

export const selectCurrency = (state: { currency: any }) => state.currency

export default currencySlice.reducer
