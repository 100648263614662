import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import FileDownload from 'js-file-download'
import api, { getAuthorizationHeader } from '../api'
import * as ProductInterface from '../helpers/interfaces/Products'

export const getProducts = createAsyncThunk(
  'products',
  async (params: ProductInterface.ParamsPages, thunkAPI) => {
    return await api
      .get('products', {
        ...params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      })
      .then((response) => {
        return response.data as ProductInterface.AllTableData
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem('isAuthenticated', 'false')
        }
        return thunkAPI.rejectWithValue(e.response.data)
      })
  },
)

export const getProductsQuotation = createAsyncThunk(
  'products/quotation',
  async (params: ProductInterface.ParamsSearch, thunkAPI) => {
    return await api
      .get('products', {
        ...params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      })
      .then((response) => {
        return response.data
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem('isAuthenticated', 'false')
        }
        return thunkAPI.rejectWithValue(e.response.data)
      })
  },
)

export const getAutocomplete = createAsyncThunk(
  'products/autocomplete',
  async (params: ProductInterface.ParamsFastSearch, thunkAPI) => {
    return await api
      .get('products', {
        ...params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      })
      .then((response) => {
        return response.data
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem('isAuthenticated', 'false')
        }
        return thunkAPI.rejectWithValue(e.response.data)
      })
  },
)

export const changeFavorite = createAsyncThunk(
  'product/changefavorite',
  async ({ data }: any, thunkAPI) => {
    return await api
      .post('product/changefavorite', data, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      })
      .then((response) => {
        response.data.product_id = data.product_id
        return response.data
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data)
      })
  },
)

export const getBrands = createAsyncThunk('brands', async (_, thunkAPI) => {
  return await api
    .get('brands', {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        localStorage.setItem('isAuthenticated', 'false')
      }
      return thunkAPI.rejectWithValue(e.response.data)
    })
})

export const getSectors = createAsyncThunk('sectors', async (_, thunkAPI) => {
  return await api
    .get('sectors', {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        localStorage.setItem('isAuthenticated', 'false')
      }
      return thunkAPI.rejectWithValue(e.response.data)
    })
})

/**
 * !Deprecated since version 1.0.3
 *
 */

export const downloadPDF = createAsyncThunk(
  'product/quotation/download',
  async ({ data }: any, thunkAPI) => {
    return await api
      .post('product/quotation/download', data, {
        responseType: 'blob',
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      })
      .then((response) => {
        return response.data
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem('isAuthenticated', 'false')
        }
        return thunkAPI.rejectWithValue(e.response.data)
      })
  },
)

const initialState = {
  isFetchingProducts: false,
  isSuccessProducts: false,
  isErrorProducts: false,
  isFetchingAutocomplete: false,
  isSuccessAutocomplete: false,
  isErrorAutocomplete: false,
  isFetchingBrands: false,
  isSuccessBrands: false,
  isErrorBrands: false,
  isFetchingSectors: false,
  isSuccessSectors: false,
  isErrorSectors: false,
  isFetchingFavorite: false,
  isSuccessFavorite: false,
  isErrorFavorite: false,
  isFetchingDownload: false,
  isSuccessDownload: false,
  isErrorDownload: false,
  descriptionsLabels: [
    {
      description: '',
    },
  ],
  errorMessage: null,
  productos: [],
  quotations: [],
  brands: [
    {
      code: '',
    },
  ],
  sectors: [
    {
      code: '',
    },
  ],
  is_favorite: 0,
  current_page: 1,
  per_page: 10,
  total: 0,
}

export const productSlice = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isFetchingProducts = false
      state.isSuccessProducts = false
      state.isErrorProducts = false
      state.errorMessage = null
      return state
    },
    clearStateAutocomplete: (state) => {
      state.isFetchingAutocomplete = false
      state.isSuccessAutocomplete = false
      state.isErrorAutocomplete = false
      state.errorMessage = null
      return state
    },
    clearStateProduct: (state) => {
      state.productos = []
      state.current_page = 1
      state.per_page = 10
      state.total = 0
      return state
    },
    clearStateQuotations: (state) => {
      state.quotations = []
      return state
    },
    clearStateFavorite: (state) => {
      state.isFetchingFavorite = false
      state.isSuccessFavorite = false
      state.isErrorFavorite = false
      state.is_favorite = 0
      return state
    },
    clearStateDownload: (state) => {
      state.isFetchingDownload = false
      state.isSuccessDownload = false
      state.isErrorDownload = false
      return state
    },
    reset: () => initialState,
  },
  extraReducers: {
    [(getProducts as any).fulfilled]: (state, { payload }) => {
      state.isFetchingProducts = false
      state.isSuccessProducts = true
      state.productos = payload.data.data
      state.current_page = payload.data.current_page
      state.per_page = payload.data.per_page
      state.total = payload.data.total
    },
    [(getProducts as any).pending]: (state) => {
      state.isFetchingProducts = false
    },
    [(getProducts as any).rejected]: (state, { payload }) => {
      state.isFetchingProducts = false
      state.isErrorProducts = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [(getProductsQuotation as any).fulfilled]: (state, { payload }) => {
      state.isFetchingProducts = false
      state.isSuccessProducts = true
      state.quotations = payload.data.data
    },
    [(getProductsQuotation as any).pending]: (state) => {
      state.isFetchingProducts = false
    },
    [(getProductsQuotation as any).rejected]: (state, { payload }) => {
      state.isFetchingProducts = false
      state.isErrorProducts = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [(getAutocomplete as any).fulfilled]: (state, { payload }) => {
      state.isFetchingAutocomplete = false
      state.isSuccessAutocomplete = true
      state.descriptionsLabels = payload.data.data
    },
    [(getAutocomplete as any).pending]: (state) => {
      state.isFetchingAutocomplete = false
    },
    [(getAutocomplete as any).rejected]: (state, { payload }) => {
      state.isFetchingAutocomplete = false
      state.isErrorAutocomplete = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [(getSectors as any).fulfilled]: (state, { payload }) => {
      state.isFetchingSectors = false
      state.isSuccessSectors = true
      state.sectors = payload.data
    },
    [(getSectors as any).pending]: (state) => {
      state.isFetchingSectors = false
    },
    [(getSectors as any).rejected]: (state, { payload }) => {
      state.isFetchingSectors = false
      state.isErrorSectors = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [(getBrands as any).fulfilled]: (state, { payload }) => {
      state.isFetchingBrands = false
      state.isSuccessBrands = true
      state.brands = payload.data
    },
    [(getBrands as any).pending]: (state) => {
      state.isFetchingBrands = false
    },
    [(getBrands as any).rejected]: (state, { payload }) => {
      state.isFetchingBrands = false
      state.isErrorBrands = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [(changeFavorite as any).fulfilled]: (state, { payload }) => {
      state.isFetchingFavorite = false
      state.isSuccessFavorite = true
      let newArray = state.quotations
      let index = newArray.findIndex((product) => (product as any).id === payload.product_id)
      if (index >= 0) {
        ;(newArray[index] as any).is_favorite = payload.data.new_is_favorite
        state.is_favorite = payload.data.new_is_favorite
        state.quotations = newArray
      }
    },

    [(changeFavorite as any).pending]: (state) => {
      state.isFetchingFavorite = false
    },
    [(changeFavorite as any).rejected]: (state, { payload }) => {
      state.isFetchingFavorite = false
      state.isErrorBrands = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [(downloadPDF as any).fulfilled]: (state, { payload }) => {
      state.isFetchingDownload = false
      state.isSuccessDownload = true
      FileDownload(payload, 'Cotización - ' + Date.now().toString() + '.pdf')
    },
    [(downloadPDF as any).pending]: (state) => {
      state.isFetchingDownload = false
    },
    [(downloadPDF as any).rejected]: (state, { payload }) => {
      state.isFetchingDownload = false
      state.isErrorDownload = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
  },
})

export const {
  clearState,
  reset,
  clearStateProduct,
  clearStateQuotations,
  clearStateFavorite,
  clearStateDownload,
} = productSlice.actions

export const selectProduct = (state: { product: any }) => state.product

export default productSlice.reducer
