import React from 'react'
import { useSelector } from 'react-redux'
import { CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react'
import { AppSidebarNav } from './AppSidebarNav'
import lubtracLogo from '../assets/brand/cotizador_sidebar.png'
import { selectUser } from '../features/userSlice'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import navigation from '../_nav'

const AppSidebar = () => {
  //const dispatch = useDispatch()
  const { unfoldable, sidebarShow, role_id } = useSelector(selectUser)

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      // onVisibleChange={(visible) => {
      //   dispatch(setSidebarShow(visible))
      // }}
      style={{
        backgroundColor: '#7c7c7c',
      }}
    >
      <CSidebarBrand className="d-none d-md-flex">
        <div className="side-logo-image">
          <img src={lubtracLogo} alt="Lubtracxl" className="img-fluid sidebar-brand-full" />
          <img src={lubtracLogo} alt="Lubtracs" className="img-fluid sidebar-brand-narrow" />
        </div>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation.filter((nav) => nav.minimumPermissions >= role_id)} />
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch(sideBarUnfoldable(!unfoldable))}
      /> */}
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
