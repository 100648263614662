import React from 'react'
import {
  CDropdownMenu,
  CDropdownToggle,
  CDropdownItem,
  CDropdown,
  CDropdownHeader,
} from '@coreui/react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { reset as ResetCurrency } from '../../features/currencySlice'
import { reset as ResetUser } from '../../features/userSlice'
import { reset as ResetProducts } from '../../features/productSlice'
import { reset as ResetQuotation } from '../../features/quotationSlice'
import { useDispatch } from 'react-redux'

function AppSessionInfo({ email }: any) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const LogOut = () => {
    localStorage.setItem('isAuthenticated', 'false')
    localStorage.removeItem('token')
    localStorage.removeItem('isAuthenticated')
    dispatch(ResetCurrency())
    dispatch(ResetUser())
    dispatch(ResetProducts())
    dispatch(ResetQuotation())
    navigate('/login')
  }

  return (
    <CDropdown variant="nav-item" style={{ textAlign: 'end' }}>
      <CDropdownToggle>
        <AccountCircleIcon />
      </CDropdownToggle>
      <CDropdownMenu>
        <CDropdownHeader>{email}</CDropdownHeader>
        {/* <CDropdownItem onClick={() => navigate('/myquotations')}>Mis cotizaciones</CDropdownItem> */}
        <CDropdownItem onClick={LogOut}>Cerrar sesión</CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

AppSessionInfo.propTypes = {
  email: PropTypes.string,
}

export default AppSessionInfo
