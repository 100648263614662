import React from 'react'
import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'

export default function FormInputEmail({
  style,
  name,
  control,
  label,
  req,
  defaultValue,
  disabled,
}: {
  style?: React.CSSProperties | undefined
  name: string
  control: any
  label: string
  req?: boolean | undefined
  defaultValue?: string | undefined
  disabled?: boolean | undefined
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: req ? 'Este campo no puede quedar vacío' : false,
        pattern: {
          value:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Ingrese una dirección de correo electrónico válida.',
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          autoFocus
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={onChange}
          autoComplete="off"
          value={value}
          disabled={disabled}
          defaultValue={defaultValue}
          fullWidth
          label={label}
          variant="outlined"
          style={style}
        />
      )}
    />
  )
}
