import React, { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const useAuth = () => {
  const isAuthenticated = localStorage.getItem('isAuthenticated')

  return isAuthenticated === 'true' ? true : false
}

function ProtectedRoutes() {
  const auth = useAuth()
  return auth ? (
    <Suspense fallback={<a>Cargando...</a>}>
      <Outlet />
    </Suspense>
  ) : (
    <Navigate to="/login" replace />
  )
}

export default ProtectedRoutes
