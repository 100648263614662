import React, { useEffect } from 'react'
import {
  Paper,
  Grid,
  Snackbar,
  makeStyles,
  Typography,
  CssBaseline,
  Button,
  Avatar,
  LinearProgress,
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import FormInputEmail from '../../../form-components/FormInputEmail'
import FormInputPassword from '../../../form-components/FormInputPassword'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser, clearState, loginUser } from '../../../features/userSlice'
import { useNavigate } from 'react-router-dom'
import { AppFooter } from '../../../components'
import cotizadorLogo from '../../../assets/brand/cotizador_login.png'
import loginImage from '../../../assets/images/login_image.png'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${loginImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#BF0811',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function Alert(props: JSX.IntrinsicAttributes & AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function Login() {
  const dispatch = useDispatch()
  const { isFetching, isSuccess, isError, errorMessage } = useSelector(selectUser)
  const classes = useStyles()
  const navigate = useNavigate()
  const { handleSubmit, control } = useForm()

  const onSubmit = (data: any) => {
    dispatch(loginUser({ data: data }) as any)
  }

  const handleClose = (_event: any, reason: any) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(clearState())
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState())
      navigate('/quotation')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className={classes.paper}>
          <img src={cotizadorLogo} alt="Lubtrac Logo" width="50%" height="auto"></img>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{ margin: '5px' }}>
            Inicio de sesión
          </Typography>
          {isFetching && <LinearProgress />}
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} style={{ alignSelf: 'center' }}>
              <FormInputEmail
                name="email"
                control={control}
                label="Correo"
                style={{ margin: '10px' }}
                req
              />
              <FormInputPassword
                name="password"
                control={control}
                label="Contraseña"
                style={{ margin: '10px' }}
                req
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                style={{ margin: '5px' }}
              >
                Iniciar sesión
              </Button>
            </Grid>
          </form>
        </div>
        <div
          style={{
            width: '100%',
            alignSelf: 'flex-end',
          }}
        >
          <AppFooter />
        </div>
      </Grid>
      <Snackbar
        open={isError}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleClose}
        key="top-right"
      >
        <Alert onClose={handleClose as any} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Grid>
  )
}
