import React, { useRef, useState, useEffect } from 'react'
import {
  CButton,
  CCloseButton,
  CCol,
  CHeaderToggler,
  CListGroup,
  CListGroupItem,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  COffcanvas,
  COffcanvasBody,
  COffcanvasHeader,
  COffcanvasTitle,
  CRow,
  CToaster,
  CSpinner,
} from '@coreui/react'

import { TextField } from '@material-ui/core'
import { NumberFormatCustom } from '../../helpers/formatters/CustomFormatter'
import CIcon from '@coreui/icons-react'
import { cilCart } from '@coreui/icons'
import { useSelector, useDispatch } from 'react-redux'
import { CartTile } from './tiles/CartTile'
import { GeneralToast } from '../../helpers/toasters/GeneralToaster'
import { selectUser, setOffCanvaShow, clearCart } from '../../features/userSlice'
import { selectCurrency } from '../../features/currencySlice'
import { saveQuotation, selectQuotation, clearState } from '../../features/quotationSlice'
import FormInputText from '../../form-components/FormInputText'
import FormInputNumber from '../../form-components/FormInputNumber'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
// import {
//   clearStateDownload,
//   selectProduct,
//downloadPDF as downloadPDFDoc,
// } from '../../features/productSlice'
import * as ProductInterface from '../../helpers/interfaces/Products'
import Badge from '@material-ui/core/Badge'

export const AppHeaderOffCanvaCart = () => {
  const dispatch = useDispatch()
  const { cart, company_id } = useSelector(selectUser)
  const { offcanvaShow } = useSelector(selectUser)
  //const { isErrorDownload, isSuccessDownload, errorMessage } = useSelector(selectProduct)
  const { currencyGlobal, currencyCoin } = useSelector(selectCurrency)
  const { isFetchingSaveQuotation, isSuccessSaveQuotation, isErrorSaveQuotation, errorMessage } =
    useSelector(selectQuotation)

  const [visibleModal, setVisibleModal] = useState(false)

  const [toast, addToast] = useState(0)
  const [data, setData] = useState<ProductInterface.CartData[]>([])
  const toaster = useRef()

  const schema = yup.object().shape({
    client: yup.string().max(255).required(),
    attendant: yup.string().max(255).required(),
    comments: yup.string().max(255).required(),
    flete: yup.string().max(255),
  })

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
  })

  const currencyCoinTranslate = (data: ProductInterface.CartData): ProductInterface.CartData => {
    let new_data: ProductInterface.CartData = { ...data }

    if (typeof new_data.price === 'string') new_data.price = parseFloat(new_data.price)
    if (typeof new_data.provider_price === 'string')
      new_data.provider_price = parseFloat(new_data.provider_price)

    if (currencyCoin !== new_data.currency_coin) {
      if (currencyCoin === 'USD' && new_data.currency_coin === 'MXN') {
        new_data.price = new_data.price / currencyGlobal
        new_data.provider_price = new_data.provider_price
          ? new_data.provider_price / currencyGlobal
          : null
        new_data.currency_coin = 'USD'
      } else if (currencyCoin === 'MXN' && new_data.currency_coin === 'USD') {
        new_data.price = new_data.price * currencyGlobal
        new_data.provider_price = new_data.provider_price
          ? new_data.provider_price * currencyGlobal
          : null
        new_data.currency_coin = 'MXN'
      }
    }

    return new_data
  }

  useEffect(() => {
    if (isErrorSaveQuotation) {
      addToast(
        GeneralToast({
          title: 'Error al descargar PDF',
          message: errorMessage,
          type: 'error',
        }) as any,
      )
    }
    if (isSuccessSaveQuotation) {
      addToast(
        GeneralToast({
          title: 'Éxito',
          message: 'La cotización fue creada exitosamente',
          type: 'success',
        }) as any,
      )
    }
    dispatch(clearState())
    setVisibleModal(false)
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSaveQuotation, isErrorSaveQuotation])

  useEffect(() => {
    setData(cart.map((element: ProductInterface.CartData) => currencyCoinTranslate(element)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  const showOffcanva = () => {
    setData(cart.map((element: ProductInterface.CartData) => currencyCoinTranslate(element)))
    dispatch(setOffCanvaShow(true))
  }

  const showModalCart = () => {
    if (cart !== null) {
      setVisibleModal(!visibleModal)
    } else {
      addToast(
        GeneralToast({
          title: 'No se puede crear la cotizacion',
          message: 'Debes tener al menos un producto en el carrito para hacer una cotizacion',
          type: 'warning',
        }) as any,
      )
    }
  }

  const deleteCart = () => {
    dispatch(clearCart())
  }

  const onSubmit = (params: any) => {
    dispatch(
      saveQuotation({ data: { ...params, products: data, currency_coin: currencyCoin } }) as any,
    )

    // dispatch(
    //   downloadPDFDoc({ data: { ...params, products: data, currency_coin: currencyCoin } }) as any,
    // )
  }

  return (
    <>
      <CToaster ref={toaster as any} push={toast as any} placement="bottom" />
      <CHeaderToggler className="ps-1" onClick={showOffcanva}>
        <Badge badgeContent={data.length} color="primary">
          <CIcon icon={cilCart} size="lg" />
        </Badge>
      </CHeaderToggler>

      <COffcanvas
        id="offcanvasNavbar"
        className="w-25 p-3"
        placement="end"
        portal={false}
        visible={offcanvaShow}
        onHide={() => dispatch(setOffCanvaShow(false))}
      >
        <COffcanvasHeader>
          <COffcanvasTitle>Cotizaciones</COffcanvasTitle>

          <CCloseButton className="text-reset" onClick={() => dispatch(setOffCanvaShow(false))} />
        </COffcanvasHeader>
        <COffcanvasHeader>
          <CListGroup flush>
            <CListGroupItem>
              Subtotal:&nbsp;&nbsp;&nbsp;
              <TextField
                id="subtotal-offcanvas"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                value={
                  data
                    ? data.reduce(
                        (previousValue: number, currentValue: ProductInterface.CartData) =>
                          previousValue + currentValue.quantity * (currentValue.price as number),
                        0,
                      )
                    : 0
                }
                variant="standard"
              />
            </CListGroupItem>
            <CListGroupItem>
              <CRow>
                <CCol xs={6}>
                  <CButton color="danger" variant="outline" onClick={deleteCart}>
                    Limpiar Carrito
                  </CButton>
                </CCol>
                <CCol xs={6}>
                  <CButton color="primary" variant="outline" onClick={showModalCart}>
                    Descargar PDF
                  </CButton>
                </CCol>
              </CRow>
            </CListGroupItem>
          </CListGroup>
        </COffcanvasHeader>

        <COffcanvasBody>
          <CListGroup flush>
            <CListGroupItem>
              {data
                ? data.map((row: ProductInterface.CartData) => (
                    <CartTile key={(row as any).id} row={row as any} />
                  ))
                : 'No hay productos en el carro'}
            </CListGroupItem>
          </CListGroup>
        </COffcanvasBody>
      </COffcanvas>

      <CModal alignment="center" visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <CModalHeader>
          <CModalTitle>Imprimir PDF</CModalTitle>
        </CModalHeader>

        <CModalBody>
          <form id="download-pdf-form" onSubmit={handleSubmit(onSubmit)}>
            {isFetchingSaveQuotation ? (
              <CSpinner color="primary" />
            ) : (
              <CListGroup flush>
                <CListGroupItem>
                  <FormInputText name="client" control={control} label="Cliente" />
                </CListGroupItem>
                <CListGroupItem>
                  <FormInputText name="attendant" control={control} label="Atencion a:" />
                </CListGroupItem>
                <CListGroupItem>
                  <FormInputText name="comments" control={control} label="Comentarios" />
                </CListGroupItem>
                {company_id > 1 && (
                  <CListGroupItem>
                    <FormInputNumber name="flete" control={control} label="Flete" />
                  </CListGroupItem>
                )}
              </CListGroup>
            )}
          </form>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" variant="outline" onClick={() => setVisibleModal(false)}>
            Cerrar
          </CButton>

          <CButton color="primary" variant="outline" type="submit" form="download-pdf-form">
            Descargar
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

const exporrtoffcanva = {
  AppHeaderOffCanvaCart,
}

export default exporrtoffcanva
