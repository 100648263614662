import axios from 'axios'

export const getToken = () => (localStorage.getItem('token') ? localStorage.getItem('token') : null)

export const getAuthorizationHeader = () => `Bearer ${getToken()}`

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: getAuthorizationHeader(),
    Accept: 'application/json',
  },
})

export default api
