import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { CButton, CCol, CListGroup, CListGroupItem, CRow, CToaster } from '@coreui/react'

import CIcon from '@coreui/icons-react'
import { cilMinus, cilPlus, cilXCircle } from '@coreui/icons'
import { GeneralToast } from '../../../helpers/toasters/GeneralToaster'
import { selectUser, updateCart } from '../../../features/userSlice'

export const CartTile = (props: { row: any }) => {
  const dispatch = useDispatch()
  const { cart } = useSelector(selectUser)

  const { row } = props

  const [toast, addToast] = useState(0)
  const toaster = useRef()

  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const addQuantityCart = () => {
    if (row.quantity + 1 > row.stock) {
      addToast(
        GeneralToast({
          title: 'No se puede agregar',
          message: 'No se puede agregar otra existencia por falta de producto',
          type: 'warning',
        }) as any,
      )
    }
    let newArray = JSON.parse(JSON.stringify(cart))
    let new_cart = newArray.map((element: { id: any; quantity: number }) => {
      if (element.id === row.id) {
        element.quantity++
        return element
      } else {
        return element
      }
    })

    dispatch(updateCart({ cart: new_cart }))
  }

  const minusQuantityCart = () => {
    if (row.quantity - 1 <= 0) {
      addToast(
        GeneralToast({
          title: 'No se puede quitar el producto',
          message: 'Se debe tener al menos un producto en la cotizacion',
          type: 'warning',
        }) as any,
      )
      return
    }

    let newArray = JSON.parse(JSON.stringify(cart))
    let new_cart = newArray.map((element: { id: any; quantity: number }) => {
      if (element.id === row.id) {
        element.quantity--
        return element
      } else {
        return element
      }
    })

    dispatch(updateCart({ cart: new_cart }))
  }

  const deleteProductCart = () => {
    let newArray = JSON.parse(JSON.stringify(cart))
    let filter = newArray.filter((element: { id: any }) => element.id !== row.id)
    dispatch(updateCart({ cart: filter }))
  }

  return (
    <>
      <CToaster ref={toaster as any} push={toast as any} placement="bottom" />
      <CListGroup flush>
        <CRow>
          <CCol xs={6}>
            <CListGroupItem>{row?.description}</CListGroupItem>
            <CListGroupItem>{row?.part_number}</CListGroupItem>
          </CCol>
          <CCol xs={6}>
            <CListGroupItem>{numberFormat.format(row?.price)}</CListGroupItem>
            <CListGroupItem>Cant: {row.quantity}</CListGroupItem>
          </CCol>
        </CRow>
        <CListGroupItem>
          <CRow>
            <CCol xs={4}>
              <CButton color="danger" variant="outline" onClick={minusQuantityCart}>
                <CIcon icon={cilMinus} size="sm" />
              </CButton>
            </CCol>

            <CCol xs={4}>
              <CButton color="success" variant="outline" onClick={addQuantityCart}>
                <CIcon icon={cilPlus} size="sm" />
              </CButton>
            </CCol>

            <CCol xs={4}>
              <CButton color="dark" variant="outline" onClick={deleteProductCart}>
                <CIcon icon={cilXCircle} size="sm" />
              </CButton>
            </CCol>
          </CRow>
        </CListGroupItem>
      </CListGroup>
    </>
  )
}

CartTile.propTypes = {
  currency: PropTypes.string,
  row: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    part_number: PropTypes.string,
    brand: PropTypes.string,
    price: PropTypes.string,
    quantity: PropTypes.number,
    stock: PropTypes.number,
  }),
}

const exportCartTiles = {
  CartTile,
}

export default exportCartTiles
