import React from 'react'
import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'

export default function FormInputText({
  name,
  control,
  label,
  defaultValue,
}: {
  name: string
  control: any
  label: string
  defaultValue?: string | undefined
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          defaultValue={defaultValue}
          variant="outlined"
          style={{ height: '100%' }}
        />
      )}
    />
  )
}
