import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import userReducer from './features/userSlice'
import currencyReducer from './features/currencySlice'
import productReducer from './features/productSlice'
import quotationReducer from './features/quotationSlice'
import marginReducer from './features/marginSlice'

const reducers = combineReducers({
  user: userReducer,
  currency: currencyReducer,
  product: productReducer,
  quotation: quotationReducer,
  margin: marginReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['product'],
}

const persistedReducer = persistReducer(persistConfig, reducers as any)

export default configureStore({
  reducer: persistedReducer,
})
