import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoutes from './components/ProtectedRoutes'
import PublicRoutes from './components/PublicRoutes'
import Login from './views/auth/login/Login'
import Register from './views/pages/register/Register'
import './scss/style.scss'
import './styles.css'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

function App() {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="*" element={<DefaultLayout />} />
      </Route>
      <Route element={<PublicRoutes />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
      </Route>
    </Routes>
  )
}

export default App
