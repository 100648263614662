import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const useAuth = () => {
  const isAuthenticated = localStorage.getItem('isAuthenticated')

  return isAuthenticated === 'true' ? true : false
}

function PublicRoutes() {
  const auth = useAuth()
  return auth ? <Navigate to="/dashboard" replace /> : <Outlet />
}

export default PublicRoutes
