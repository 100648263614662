import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'

// routes config
import { routes } from '../routes'
import { useSelector } from 'react-redux'
import { selectUser } from '../features/userSlice'

const AppContent = () => {
  const { role_id } = useSelector(selectUser)

  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {routes
            .filter((route) => route.minimumPermissions >= role_id)
            .map((route, idx) => {
              return (
                route.element && <Route key={idx} path={route.path} element={<route.element />} />
              )
            })}
          <Route path="" element={<Navigate to="/quotation" replace />} />
        </Routes>
      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
