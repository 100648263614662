import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderToggler,
  CRow,
  CCol,
  CHeaderNav,
  CToaster,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'
// import CIcon from '@coreui/icons-react'
// import { cilMenu } from '@coreui/icons'
import { selectUser } from '../features/userSlice'
//import { AppBreadcrumb } from './index'
import { AppHeaderOffCanvaCart } from './header/AppHeaderOffCanvaCart'
import { Typography, TextField, InputAdornment, CircularProgress } from '@material-ui/core'
import {
  selectCurrency,
  changeCoinCurrency,
  changeCurrencyGlobal,
  getCurrencyBanxico,
  getCurrentCurrency,
  //getProductLastFileUpdate,
  updateCurrency,
  clearState,
} from '../features/currencySlice'
import { selectMargin, getCurrentMargin, postMargin, clearStatePost } from '../features/marginSlice'
import AppSessionInfo from './header/AppSessionInfo'
import { GeneralToast } from '../helpers/toasters/GeneralToaster'

const AppHeader = () => {
  const dispatch = useDispatch()
  const { email, role_id } = useSelector(selectUser)
  const {
    isFetchingBanxico,
    isErrorBanxico,
    isSuccessBanxico,
    // isFetchingLastUpdate,
    // isSuccessLastUpdate,
    errorMessage,
    isFetchingCurrency,
    // isSuccessCurrency,
    isErrorCurrency,
    isSuccessUpdateCurrency,
    isFetchingUpdateCurrency,
    isErrorUpdateCurrency,
    currencyGlobal,
    currencyCoin,
    bmxdolar,
    //currencyLastUpdate,
  } = useSelector(selectCurrency)

  const {
    minimum_margin,
    maximum_discount,
    isSuccessPost,
    isErrorPost,
    isFetchingPost,
    errorMessage: errorMessageMargin,
  } = useSelector(selectMargin)

  const [modalVisible, setModalVisible] = useState(false)

  const [currency, setCurrency] = useState(currencyGlobal)

  const [margin, setMargin] = useState(minimum_margin)
  const [discount, setDiscount] = useState(maximum_discount)

  const [toast, addToast] = useState(0)
  const toaster = useRef()

  const changeCurrency = (event: { target: { value: any } }) => {
    //dispatch(changeCurrencyGlobal({ value: event.target.value }))
    setCurrency(event.target.value)
  }

  const changeMargin = (event: { target: { value: any } }) => {
    setMargin(event.target.value)
  }

  const changeDiscount = (event: { target: { value: any } }) => {
    setDiscount(event.target.value)
  }

  useEffect(() => {
    dispatch(getCurrencyBanxico() as any)
    dispatch(getCurrentCurrency() as any)
    dispatch(getCurrentMargin() as any)
    //dispatch(getProductLastFileUpdate() as any)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCurrency(currencyGlobal)
  }, [currencyGlobal])

  useEffect(() => {
    let show_error = true
    if (errorMessage !== null && show_error) {
      addToast(
        GeneralToast({
          title: 'Ha ocurrido un error',
          message: errorMessage,
          type: 'error',
        }) as any,
      )
      dispatch(clearState())
    }
    return () => {
      show_error = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage])

  useEffect(() => {
    if (isSuccessUpdateCurrency) {
      dispatch(changeCurrencyGlobal({ value: currency }))
      addToast(
        GeneralToast({
          title: 'Se actualizo la tasa de cambio',
          message: 'La nueva tasa de cambio es ' + currency,
          type: 'success',
        }) as any,
      )
      dispatch(clearState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdateCurrency])

  useEffect(() => {
    if (isSuccessPost) {
      addToast(
        GeneralToast({
          title: 'Exito',
          message: 'Los valores se han actualizado exitosamente',
          type: 'success',
        }) as any,
      )
      dispatch(clearStatePost())
    }
  }, [isSuccessPost])

  useEffect(() => {
    if (isErrorUpdateCurrency || isErrorBanxico || isErrorCurrency || isErrorPost) {
      addToast(
        GeneralToast({
          title: 'Ha ocurrido un error',
          message: errorMessage,
          type: 'error',
        }) as any,
      )
      dispatch(clearState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, errorMessageMargin])

  useEffect(() => {
    if (isSuccessBanxico) {
      addToast(
        GeneralToast({
          title: 'Exito',
          message: 'Tipo de cambio del Banco de México actualizado.',
          type: 'success',
        }) as any,
      )
      dispatch(clearState())
    }
  }, [bmxdolar])

  const updateCurr = (_event: any) => {
    let data = {
      currency_value: currency,
    }

    dispatch(updateCurrency({ data: data }) as any)
  }

  const updateMar = (_event: any) => {
    let data = {
      minimum_margin: margin,
    }

    dispatch(postMargin({ data: data }) as any)
  }

  const updateDis = (_event: any) => {
    let data = {
      maximum_discount: discount,
    }

    dispatch(postMargin({ data: data }) as any)
  }

  const updateBanxico = (_event: any) => {
    dispatch(getCurrencyBanxico() as any)
  }

  return (
    <CHeader position="sticky" className="mb-4">
      <CToaster ref={toaster as any} push={toast as any} placement="bottom" />
      <CContainer fluid className="mt-2 mb-2">
        {/* <CHeaderToggler className="ps-1" onClick={() => dispatch(setSidebarShow(!sidebarShow))}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler> */}
        <CCol xs={4}>
          <CHeaderNav>
            <CButton
              style={{
                height: '100%',
              }}
              color="primary"
              size="lg"
              variant="ghost"
              onClick={() => setModalVisible(true)}
            >
              Ajustes
            </CButton>
          </CHeaderNav>
        </CCol>
        <CCol
          xs={4}
          style={{
            alignItems: 'center',
            alignSelf: 'center',
            textAlign: 'center',
          }}
        >
          <CContainer fluid>
            <CHeaderNav
              style={{
                justifyContent: 'center',
              }}
            >
              <CHeaderToggler
                onClick={() =>
                  dispatch(changeCoinCurrency({ value: currencyCoin === 'MXN' ? 'USD' : 'MXN' }))
                }
              >
                <Typography variant="h5">Moneda: {currencyCoin === 'MXN' ? '🇲🇽' : '🇺🇸'}</Typography>
              </CHeaderToggler>
            </CHeaderNav>
          </CContainer>
        </CCol>
        <CCol xs={4}>
          <CContainer fluid>
            <CHeaderNav
              style={{
                justifyContent: 'end',
              }}
            >
              <AppHeaderOffCanvaCart />
              <AppSessionInfo email={email} />
            </CHeaderNav>
          </CContainer>
        </CCol>
      </CContainer>
      <CModal
        alignment="center"
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false)
        }}
      >
        <CModalHeader>
          <CModalTitle>Ajustes</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CCol xs={12}>
              <CCol xs={12} className="p-3">
                <CContainer fluid>
                  <CRow>
                    <CCol xs={8}>
                      {isFetchingBanxico ? (
                        <CircularProgress />
                      ) : (
                        <TextField
                          label="Banxico"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={bmxdolar}
                          value={bmxdolar}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          variant="outlined"
                          disabled
                        />
                      )}
                    </CCol>
                    <CCol xs={4}>
                      <CButton
                        style={{
                          height: '100%',
                        }}
                        color="primary"
                        size="lg"
                        variant="outline"
                        onClick={updateBanxico}
                        disabled={isFetchingBanxico}
                      >
                        Actualizar
                      </CButton>
                    </CCol>
                  </CRow>
                </CContainer>
              </CCol>
              <CCol xs={12} className="p-3">
                <CContainer fluid>
                  <CRow>
                    <CCol xs={8}>
                      {isFetchingCurrency || isFetchingUpdateCurrency ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <TextField
                            label="Divisa de cambio"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={!(role_id && role_id < 4)}
                            value={currency}
                            onChange={role_id && role_id < 4 ? changeCurrency : () => {}}
                            onLoad={role_id && role_id < 4 ? (changeCurrency as any) : () => {}}
                            defaultValue={0.0}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            variant="outlined"
                          />
                        </>
                      )}
                    </CCol>
                    <CCol xs={4}>
                      <CButton
                        style={{
                          height: '100%',
                        }}
                        color="primary"
                        size="lg"
                        variant="outline"
                        onClick={updateCurr}
                        disabled={
                          currency === currencyGlobal ||
                          isFetchingCurrency ||
                          isFetchingUpdateCurrency ||
                          !(role_id && role_id < 4)
                        }
                      >
                        Actualizar
                      </CButton>
                    </CCol>
                  </CRow>
                </CContainer>
              </CCol>
              {role_id !== 4 ? (
                <>
                  <CCol xs={12} className="p-3">
                    <CContainer fluid>
                      <CRow>
                        <CCol xs={8}>
                          {isFetchingPost ? (
                            <CircularProgress />
                          ) : (
                            <>
                              <TextField
                                label="Margen mínimo"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={!(role_id && role_id < 4)}
                                value={margin}
                                onChange={role_id && role_id < 4 ? changeMargin : () => {}}
                                onLoad={role_id && role_id < 4 ? (changeMargin as any) : () => {}}
                                defaultValue={0.0}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">%</InputAdornment>
                                  ),
                                }}
                                variant="outlined"
                              />
                            </>
                          )}
                        </CCol>
                        <CCol xs={4}>
                          <CButton
                            style={{
                              height: '100%',
                            }}
                            color="primary"
                            size="lg"
                            variant="outline"
                            onClick={updateMar}
                            disabled={!(role_id && role_id < 4) || margin === minimum_margin}
                          >
                            Actualizar
                          </CButton>
                        </CCol>
                      </CRow>
                    </CContainer>
                  </CCol>
                  <CCol xs={12} className="p-3">
                    <CContainer fluid>
                      <CRow>
                        <CCol xs={8}>
                          {isFetchingPost ? (
                            <CircularProgress />
                          ) : (
                            <>
                              <TextField
                                label="Descuento máximo"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={!(role_id && role_id < 4)}
                                value={discount}
                                onChange={role_id && role_id < 4 ? changeDiscount : () => {}}
                                onLoad={role_id && role_id < 4 ? (changeDiscount as any) : () => {}}
                                defaultValue={0.0}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">%</InputAdornment>
                                  ),
                                }}
                                variant="outlined"
                              />
                            </>
                          )}
                        </CCol>
                        <CCol xs={4}>
                          <CButton
                            style={{
                              height: '100%',
                            }}
                            color="primary"
                            size="lg"
                            variant="outline"
                            onClick={updateDis}
                            disabled={!(role_id && role_id < 4) || discount === maximum_discount}
                          >
                            Actualizar
                          </CButton>
                        </CCol>
                      </CRow>
                    </CContainer>
                  </CCol>
                </>
              ) : null}
            </CCol>
          </CContainer>
        </CModalBody>
      </CModal>
    </CHeader>
  )
}

export default AppHeader
