import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import FileDownload from 'js-file-download'
import api, { getAuthorizationHeader } from '../api'

export const saveQuotation = createAsyncThunk('quotations', async ({ data }: any, thunkAPI) => {
  return await api
    .post('quotations', data, {
      responseType: 'blob',
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
    .then((response) => {
      return response.data
    })
    .catch(async (e) => {
      let responseObj = await e.response.data.text()
      if (e.response.status === 401) {
        localStorage.setItem('isAuthenticated', 'false')
      }
      return thunkAPI.rejectWithValue(responseObj)
    })
})

export const getMyQuotations = createAsyncThunk(
  'quotations/myquotations',
  async (params: any, thunkAPI) => {
    return await api
      .get('quotations/myquotations', {
        ...params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      })
      .then((response) => {
        return response.data as any
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem('isAuthenticated', 'false')
        }
        return thunkAPI.rejectWithValue(e.response.data)
      })
  },
)

export const getQuotationPDF = createAsyncThunk('quotations/pdf', async (params: any, thunkAPI) => {
  return await api
    .get('quotations/pdf', {
      responseType: 'blob',
      ...params,
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
    .then((response) => {
      return response.data as any
    })
    .catch((e) => {
      if (e.response.status === 401) {
        localStorage.setItem('isAuthenticated', 'false')
      }
      return thunkAPI.rejectWithValue(e.response.data)
    })
})

const initialState = {
  myquotations: [],
  isFetchingSaveQuotation: false,
  isSuccessSaveQuotation: false,
  isErrorSaveQuotation: false,
  isFetchingMyQuotations: false,
  isSuccessMyQuotations: false,
  isErrorMyQuotations: false,
  isFetchingPDF: false,
  isSuccessPDF: false,
  isErrorPDF: false,
  errorMessage: null,
}

export const quotationSlice = createSlice({
  name: 'quotation',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isFetchingSaveQuotation = false
      state.isSuccessSaveQuotation = false
      state.isErrorSaveQuotation = false
      state.errorMessage = null
      return state
    },
    clearStateMyQuotations: (state) => {
      state.isFetchingSaveQuotation = false
      state.isSuccessSaveQuotation = false
      state.isErrorSaveQuotation = false
      state.errorMessage = null
      return state
    },
    clearStatePDF: (state) => {
      state.isFetchingPDF = false
      state.isSuccessPDF = false
      state.isErrorPDF = false
      state.errorMessage = null
      return state
    },
    reset: () => initialState,
  },
  extraReducers: {
    [(saveQuotation as any).fulfilled]: (state, { payload }) => {
      state.isFetchingSaveQuotation = false
      state.isSuccessSaveQuotation = true
      FileDownload(payload, 'Cotización -' + Date.now().toString() + '.pdf')
    },
    [(saveQuotation as any).pending]: (state) => {
      state.isFetchingSaveQuotation = true
    },
    [(saveQuotation as any).rejected]: (state, { payload }) => {
      state.isFetchingSaveQuotation = false
      state.isErrorSaveQuotation = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [(getMyQuotations as any).fulfilled]: (state, { payload }) => {
      state.isFetchingMyQuotations = false
      state.isSuccessMyQuotations = true
      state.myquotations = payload.data
    },
    [(getMyQuotations as any).pending]: (state) => {
      state.isFetchingMyQuotations = true
    },
    [(getMyQuotations as any).rejected]: (state, { payload }) => {
      state.isFetchingMyQuotations = false
      state.isErrorMyQuotations = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
    [(getQuotationPDF as any).fulfilled]: (state, { payload }) => {
      state.isFetchingPDF = false
      state.isSuccessPDF = true
      FileDownload(payload, 'Cotización -' + Date.now().toString() + '.pdf')
    },
    [(getQuotationPDF as any).pending]: (state) => {
      state.isFetchingPDF = true
    },
    [(getQuotationPDF as any).rejected]: (state, { payload }) => {
      state.isFetchingPDF = false
      state.isErrorPDF = true
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + '. ' + JSON.stringify(payload.data)
          : payload.message
        : 'Error en el servidor'
    },
  },
})

export const { clearState, clearStatePDF, reset } = quotationSlice.actions

export const selectQuotation = (state: { quotation: any }) => state.quotation

export default quotationSlice.reducer
