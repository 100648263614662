import React from 'react'
import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'

export default function FormInputPassword({
  style,
  name,
  control,
  label,
  req,
}: {
  style?: React.CSSProperties | undefined
  name: string
  control: any
  label: string
  req: boolean
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: req ? 'Este campo no puede quedar vacío' : false,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          autoComplete="new-password"
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          type="password"
          label={label}
          variant="outlined"
          style={style}
        />
      )}
    />
  )
}
